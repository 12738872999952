@charset "utf-8";
@import "_normalize.scss";
@import "_grid.scss";

@font-face {
    font-family: 'Fragment Core';
    src: url(../fonts/fragmentcore.otf)
}

@font-face {
    font-family: 'Glypha LT';
    src: url(../fonts/glypha_lt.ttf)
}

@font-face {
    font-family: 'Font Shui';
    src: url(../fonts/fontshui.ttf)
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Fragment Core', 'Times New Roman', serif;
    font-size: 17px;
    line-height: 22px;
    color: #111;
    -webkit-font-smoothing: antialiased;
}

.no-overflow {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    position: static;
    height: auto;
}

a {
    text-decoration: none;
    color: #111;
    &:focus {
        outline: none;
    }
}

h1, h2 {
    font-family: 'Font Shui';

}

h1 {
    font-size: 28px;
    margin: 0 20px 25px 20px;
    line-height: 38px;
}

h2 {
    margin: 0 20px 0 20px;
    font-size: 18px;
    line-height: 24px;
}

.center {
    text-align: center;
    margin: 0 auto;
    display: block;
}

.right {
    float: right;
}

.wrapper {
    width: 100%;
    background: #eaeae0;
}

.image-wrapper {
    background-size: cover;
    background-position: center center;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

header {
    padding-top: 70px;
    position: absolute;
    width: 100%;
}

.menu-button {
    opacity: 1;
    left: 20px;
    top: 20px;
    z-index: 5001;
    position: absolute;
    display: block;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 10px;
    span {
        background-color: #fff;
        position: absolute;
        width: 30px;
        height: 3px;
        transition: all 0.3s 0s, top 0.3s 0s;
    }
    .top {
        top: 12px;
        margin-top: 0;
        transition: all 0.3s 0s, top 0.3s 0s;
    }
    .middle {
        top: 20px;
        transition: opacity, 0.3s, 0.2s;
    }
    .bottom {
        top: 28px;
        margin-top: 0;
        transition: all 0.3s 0s, top 0.3s 0s;
    }
}

.on {
    span {
        background-color: #333;
    }
    .top {
        top: 24px;
        transform: rotate(45deg);
    }
    .middle {
        opacity: 0;
    }
    .bottom {
        transform: rotate(-45deg);
        top: 24px;
    }
}

.hideme {
    opacity: 0;
}

nav {
    ul {
        display: none;
        z-index: 1000;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        list-style: none;
        background: rgba(234, 234, 224, .98);
        margin: 0;
        padding: 100px 0 0 0;
        &:before {
            content: "";
            background: url('../images/icon-menu-dark.svg');
            width: 70px;
            height: 50px;
            position: absolute;
            left: 50%;
            margin-left: -35px;
            top: 60px;
        }
        li {
            text-align: center;
            &:first-child {
                margin-top: 30px;
            }
            a {
                font-family: 'Font Shui';
                line-height: 60px;
                padding-bottom: 10px;
                border-bottom: 2px solid transparent;
                color: #111;
                text-decoration: none;
                transition: all 0.3s;
                font-size: 1.375em;
                &:hover {
                    border-color: #fff;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

.logo {
    width: 95%;
    max-width: 500px;
    display: block;
    padding: 0 30px;
    margin: 0 auto;
}

.image-one {
    height: 100vh;
    position: static;
    background-image: url(../images/surfers-top.jpg);
    text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    font-size: 1.375em;
    text-align: center;
    background-position: top center;
    color: #fff;
    p {
        padding: 0 40px;
        font-weight: 700;
        letter-spacing: 1px;
    }
}

.image-two {
    position: static;
    background-image: url(../images/surfers-2.jpg);
}

.image-three {
    position: static;
    background-image: url(../images/surfers-3.jpg);
}

.image-four {
    position: static;
    background-image: url(../images/surfers-4.jpg);
}

.image-five {
    position: static;
    background-image: url(../images/surfers-5.jpg);
}

.footer-image {
    position: static;
    background-image: url(../images/surfers-top.jpg);
    color: #fff;
    background-size: cover;
    background-position: center center;
}

.no-pad {
    p:first-child {
        margin-top: 0;
    }
}

.overlay {
    position: absolute;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    .take-away {
        width: 90%;
        max-width: 450px;
        background: #eaeae0;
        padding: 30px;
        text-align: center;
        position: relative;
    }
    img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: auto;
        transition: transform 0.3s;
        cursor: pointer;
        &:hover {
            transform: rotate(90deg);
        }
    }
    &.on {
        pointer-events: all;
        opacity: 1;
    }

}

.arrow {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 35px;
}

.deco {
    padding: 20px 0;
}

.pad {
    padding: 60px 30px;
    text-align: center;
}

.quote {
    text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    font-size: 1em;
    color: #fff;
    padding: 0 30px;
    font-weight: 700;
    line-height: 1.2em;
    em {
        padding: 15px 0;
        position: relative;
        &:after {
            content: "";
            height: 2px;
            width: 30px;
            background: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    span {
        display: block;
        padding-top: 27px;
    }
}

.restaurant {
    text-align: center;
}

.menu {
    .pad {
        &:first-child {
            padding-bottom: 10px;
        }
        &:last-child {
            padding-top: 30px;
        }
    }
}

.menu-toggle {
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;

    span {
        position: relative;
        cursor: pointer;

        &:after {
            background: #111;
            transition: background 250ms;
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            bottom: -2px;
            left: 0;
        }
        &:hover {
            &:after {
                background: transparent;
            }
        }

        &.selected {
            cursor: auto;

            &:after {
                background: transparent;
            }
        }
    }
}

.show {
    display: flex;
}

.hide {
    display: none;
}

hr {
    background: transparent;
    border: 1px dashed #eaeae0;
}

.dessert {
    background: #333;
    color: #eaeae0;
    padding: 60px 25px;
}

.news-times {
    border: 4px double #000;
    padding: 20px;
    margin: 30px 30px 30px 30px;
    text-align: center;
}

.welcome .highlight-text {
    position: relative;
    z-index: 100;
}

.umbrellas {
    position: absolute;
    right: 20px;
    bottom: -20px;
    opacity: .5;
}

.date {
    font-size: 0.778em;
    position: relative;
    padding-bottom: 15px;
    &:after {
        content: "";
        height: 2px;
        width: 30px;
        background: #333;
        position: absolute;
        left: 50%;
        margin-left: -15px;
        bottom: 0;
    }
}

.highlight-text {
    font-family: 'Glypha LT';
}

.restaurant-image {
    background-image: url(../images/surfers-8.jpg);
    background-position: center center;
    background-size: cover;
    max-width: 100%;
    height: 300px;
    padding: 0 20px;
}

.bar {
    background-image: url(../images/surfers-6.jpg);
    background-position: center center;
    background-size: cover;
    max-width: 100%;
    height: 300px;
    padding: 0 20px;
}

footer {
    background-color: rgba(29, 29, 27, .7) !important;
    width: 100%;
    padding: 30px 0;
    margin-top: 0;
    color: #fff;
    a {
        color: #fff;
    }
    [class*="span"] {
        padding: 0;
    }

    .pad {
        padding: 60px 30px 30px;
    }
}

.footer-icon {
    width: 85%;
    max-width: 100px;
    padding-bottom: 90%;
    background-size: cover;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.footer-icon.up {
    background-image: url(../images/icon-up.svg);
}

.footer-icon.fb {
    background-image: url(../images/icon-fb.svg);
}

.footer-icon.instagram {
    background-image: url(../images/icon-instagram.svg);
}

.footer-icon.wg {
    background-image: url(../images/icon-wg.svg);
}

.copyright {
    padding: 10px 25px 0 25px;
    font-size: 0.778em;
    color: #fff;
    a {
        color: #fff;
        position: relative;

        &:after {
            background: #ccc;
            transition: background 250ms;
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            bottom: -2px;
            left: 0;
        }

        &:hover {
            &:after {
                background: transparent;
            }
        }
    }
}

.copyright a:hover {
    color: #eaeae0
}

.copyright a:active {
    color: #eaeae0
}

.copyright a:visited {
    color: #eaeae0
}

button {
    margin: 25px auto 0 auto;
    display: block;
    color: #eaeae0;
    background: #111;
    font-family: 'Glypha LT';
    padding: 15px 35px;
    border: 4px double #111;
    outline: none;
    transition: all 0.3s;
    &:hover {
        background: transparent;
        color: #111;
    }
}

.insta {
    padding-top: 30px;

    a {
        p {
            font-size: 24px;
            margin: -5px 0 25px;

            @media only screen and (min-width: 768px) {
                margin: 0 0 10px;
            }
        }
    }

    .q p {
        font-size: 18px;
    }
}

#instafeed .img {
    @include aspect-ratio(1, 1);
    background-size: cover;
    background-position: center center;
    -webkit-filter: contrast(110%);
    filter: contrast(110%);
    transition: all 0.2s;
    position: relative;
    &.profile-picture {
        background: url(../images/insta-logo.svg);
        background-size: cover;
    }
}

#instafeed .likes {
    background: rgba(29, 29, 27, .8);
    font-family: sans-serif;
    font-size: 1em;
    position: absolute;
    display: flex;
    color: #fff;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    text-shadow: 0 1px rgba(0, 0, 0, .5);
    transition: all .3s;
    justify-content: center;
    align-items: center;
}

#instafeed a:hover .likes {
    opacity: 1
}

@-webkit-keyframes pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }
}

@keyframes pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }
}

.pulse {
    display: inline-block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

#map {
    height: 220px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    margin-top: 25px;
}

a[href^="http://maps.google.com/maps"] {
    display: none !important
}

.gm-style-cc {
    display: none
}

#cd-zoom-in, #cd-zoom-out {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-left: 10px;
    background-color: rgba(29, 29, 27, 1);
    background-repeat: no-repeat;
    background-size: 32px 64px;
    background-image: url(../images/icon-zoom.svg)
}

#cd-zoom-in:hover, #cd-zoom-out:hover {
    background-color: rgba(29, 29, 27, .9)
}

@media only screen and (min-width: 768px) {
    #cd-zoom-in, #cd-zoom-out {
        margin-left: 10px
    }
}

#cd-zoom-in {
    background-position: 50% 0;
    margin-top: 0;
    margin-bottom: 2px
}

#cd-zoom-out {
    background-position: 50% -32px
}

.anchor {
    display: block;
    position: relative;
    top: -30px;
    visibility: hidden
}

.dessert-wrapper {
    padding: 0;
}

@media only screen and (min-width: 768px) {
    body {
        font-size: 18px;
    }

    h1 {
        margin: 0 0 25px 0;
    }

    h2 {
        margin: 0;
    }

    .menu-button {
        display: none;
    }

    nav {

        ul {
            position: relative;
            max-width: 1024px;
            margin: 0 auto;
            padding: 0;
            list-style: none;
            background: none;
            display: flex;
            top: 0;
            &:before {
                content: "";
                background: none;
                width: 0;
                height: 0;
            }
            li {
                flex-grow: 1;
                text-align: center;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    position: relative;
                    color: #fff;
                    line-height: 54px;
                    padding-bottom: 10px;
                    border-bottom: 2px solid transparent;
                    text-decoration: none;
                    transition: all 0.3s;
                    font-size: 1em;
                    &:before {
                        content: url('../images/icon-menu.svg');
                        position: absolute;
                        width: 50px;
                        height: auto;
                        top: -40px;
                        left: 50%;
                        margin-left: -25px;
                        opacity: 0;
                        transition: all 0.3s;
                        transform: rotate(0deg);
                    }
                    &:hover {
                        border-color: #fff;
                        padding-bottom: 4px;
                        &:before {
                            opacity: 1;
                            transform: rotate(-15deg);
                        }
                    }
                }

            }
        }
    }
    .pad {
        padding: 25px;
        text-align: left;
    }
    .anchor {
        top: -60px;
        visibility: hidden
    }

    .wrapper {
        padding: 25px 0;
    }
    .image-wrapper {
        height: 100vh;
        background-attachment: fixed;
    }

    .stitch {
        background-image: url(../images/stitch.svg);
        width: 100%;
        height: 1px;
    }
    .restaurant-image {
        flex: 1 1 auto;
        margin: 35px 0;
        height: auto;
    }

    .bar {
        flex: 1 1 auto;
        margin: 35px 0;
        height: auto;
    }
    .umbrellas {
        bottom: 0;
        opacity: 1;
    }
    .quote {
        padding: 0 100px;
        font-size: 1.375em;
    }
    .no-pad {
        p:first-child {
            margin-top: 18px;
        }
    }
    .menu {
        .pad {
            &:first-child {
                border-bottom: none;
            }
        }
    }

    .restaurant {
        padding: 60px;
        text-align: left;
    }

    .footer-icon {
        width: 100%;
        max-width: 100px;
        padding-bottom: 88%;
    }

    .footer-icon:hover {
        background-position: 0 -100px
    }

    #map {
        height: 400px;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        z-index: 10;
        margin: 20px;
    }

}
